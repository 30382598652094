<!-- table-inner -->
<template>
  <div v-dialogDrag="dialogDragShow">
    <el-dialog :visible.sync="localDialogVisible" v-bind="$attrs" :close-on-click-modal="closeOnClickModal" @close="close" @open="open">
      <slot />
      <template v-if="showFooter" #footer>
        <div class="dialog-footer">
          <slot name="btn-before" />
          <el-button v-if="showCancel" :loading="loading" @click="cancel">
            {{ cancelTitle }}
          </el-button>
          <slot name="btn-center" />
          <debounce-button v-if="showSubmit" type="primary" :loading="loading" @click="submit">
            {{ submitTitle }}
          </debounce-button>
          <slot name="btn-behind" />
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { replace } from 'lodash'
export default {
  name: 'IcsDialogInner',
  components: {
  },
  directives: {
    dialogDrag: {
      bind (el, binding) {
        if (!binding.value) return false
        const dialogHeaderEl = el.querySelector('.el-dialog__header')
        const dragDom = el.querySelector('.el-dialog')
        // dialogHeaderEl.style.cursor = 'move';
        dialogHeaderEl.style.cssText += ';cursor:move;'
        dragDom.style.cssText += ';top:0px;'
        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const sty = (function () {
          if (window.document.currentStyle) {
            return (dom, attr) => dom.currentStyle[attr]
          } else {
            return (dom, attr) => getComputedStyle(dom, false)[attr]
          }
        })()

        dialogHeaderEl.onmousedown = (e) => {
          // 鼠标按下，计算当前元素距离可视区的距离
          const disX = e.clientX - dialogHeaderEl.offsetLeft
          const disY = e.clientY - dialogHeaderEl.offsetTop

          const screenWidth = document.body.clientWidth // body当前宽度
          const screenHeight = document.documentElement.clientHeight // 可见区域高度(应为body高度，可某些环境下无法获取)

          const dragDomWidth = dragDom.offsetWidth // 对话框宽度
          const dragDomheight = dragDom.offsetHeight // 对话框高度

          const minDragDomLeft = dragDom.offsetLeft
          const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth

          const minDragDomTop = dragDom.offsetTop
          const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight

          // 获取到的值带px 正则匹配替换
          let styL = sty(dragDom, 'left')
          let styT = sty(dragDom, 'top')

          // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
          if (styL.includes('%')) {
            styL = +document.body.clientWidth * (+replace(styL, '%', '') / 100)
            styT = +document.body.clientHeight * (+replace(styT, '%', '') / 100)
          } else {
            styL = +replace(styL, 'px', '')
            styT = +replace(styT, 'px', '')
          }

          document.onmousemove = function (e) {
            // 通过事件委托，计算移动的距离
            let left = e.clientX - disX
            let top = e.clientY - disY

            // 边界处理
            if (-(left) > minDragDomLeft) {
              left = -(minDragDomLeft)
            } else if (left > maxDragDomLeft) {
              left = maxDragDomLeft
            }

            if (-(top) > minDragDomTop) {
              top = -(minDragDomTop)
            } else if (top > maxDragDomTop) {
              top = maxDragDomTop
            }

            // 移动当前元素
            dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`
          }

          document.onmouseup = function () {
            document.onmousemove = null
            document.onmouseup = null
          }
          return false
        }
      },
      update (el) {
        const dragDom = el.querySelector('.el-dialog')
        dragDom.style.cssText += ';top:0px;left:0px;'
      }
    }
  },
  props: {
    dialogDragShow: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showSubmit: {
      type: Boolean,
      default: true
    },
    closeVisible: {
      type: Boolean,
      default: true
    },
    cancelVisible: {
      type: Boolean,
      default: true
    },
    submitTitle: {
      type: String,
      default: '确 定'
    },
    cancelTitle: {
      type: String,
      default: '取 消'
    },
    closeOnClickModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localDialogVisible: false
    }
  },
  watch: {
    visible: {
      handler (val) {
        this.localDialogVisible = val
      },
      immediate: true
    }
  },
  created () {
  },
  methods: {
    close () {
      if (this.closeVisible) {
        this.$emit('update:visible', false)
      }
      this.$emit('close')
    },
    cancel () {
      if (this.cancelVisible) {
        this.$emit('update:visible', false)
      }
      this.$emit('cancel')
    },
    open () {
      this.$emit('open')
    },
    submit () {
      this.$emit('submit')
    }
  }
}
</script>
<style>
</style>
